<template>
    <router-view v-if="doRender" />
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'authLayer',
  data() {
    return {
      checkingAuth: true,
      initialized: new Promise(async complete => {
        // This bootstraps initial state, allowing other code to wait for execution
        // until exactly the moment initialization is complete.
        await this.checkAuth(true)
        complete()
      }),
    }
  },
  computed: {
    ...mapState(['realmName', 'identity']),
    ssoParams() {
      return {
        actionURL: this.$route.query.action,
        appName: this.$route.query.client_id,
      }
    },
    ssoLoginRequest() {
      return !!(this.$route.query.action && this.$route.query.client_id)
    },
    nextPath() {
      return `/${this.realmName}${this.$route.query.p || '/applications'}`
    },
    doRender() {
      // Do not render if auth is being checked.
      if (this.checkingAuth) {
        return false
      }
      // Determine if the required state for render is present.
      if (this.$route.meta.requiresAuth) {
        return !!this.identity
      } else if (this.$route.meta.requiresNoAuth) {
        return !this.identity
      } else {
        return true
      }
    }
  },
  methods: {
    ...mapActions(['bootstrap', 'ssoLogin', 'frontendLogout']),
    async checkAuth(addPath) {
      // Indicate an auth check is occuring.
      // this suppresses any render until it is complete and disables
      // the watch on identity to this from running multiple times
      this.checkingAuth = true
      // make sure the app is properly bootstrapped before doing anything
      await this.bootstrap(this.$route.params.realmName.toLowerCase())
      // If this is a logout requests, process it
      if (this.$route.name === 'logout') {
        const complete = await this.frontendLogout(this.$route.query)
        if(!complete) {
          this.$router.replace(`/${this.realmName}/login`)
          return
        }
      }
      // If this is an sso login flow... do the login flow
      // The app is bootstrapped, so we now have an identity if it is available.
      if (this.$route.meta.processLogin && this.ssoLoginRequest) {
        const complete = await this.ssoLogin(this.ssoParams)
        if (complete) {
          return
        }
      }
      // If the route requires auth and no identity is set, redirect to the login page.
      // If addPath is true, this adds the p parameter to the redirect with the requested
      // path added to it.
      if (this.$route.meta.requiresAuth && !this.identity) {
        const components = this.$route.fullPath.substring(1).split('/')
        const pathRealm = components.shift()
        const path = `/${components.join('/')}`
        const tag = pathRealm === this.realmName && addPath && path !== '/' ? `?p=${encodeURIComponent(path)}` : ''
        this.$router.replace(`/${this.realmName}/login${tag}`)
      // If the router requires the absense of auth and an identity is present, go to the next path requested
      // with the root profile page as the default.
      } else if (this.$route.meta.requiresNoAuth && !!this.identity) {
        this.$router.replace(this.nextPath)
      }
      // The auth check is done, allow renders to proceed again.
      this.checkingAuth = false
    },
  },
  watch: {
    async identity() {
      // Only work when initialization is complete.
      await this.initialized
      // If an authentication check is already underway, do not run it again.
      if (!this.checkingAuth) {
        await this.checkAuth(false)
      }
    }
  },
}
</script>
